<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 class="font-weight-light title-product-home-size">{{ $t('home.product.bestSeller') }}</h1>
        <!-- <div
          style="display: flex; justify-content: center; align-items: center; overflow: auto; white-space: nowrap;"
        >
          <span v-for="category in categories" :key="category.id" class="font-large cursor-pointer category-hover px-2 py-2" @click="getProductList(category)">{{ category.name }}</span>
        </div> -->
        <!-- Product list -->
        <v-row>
          <v-col cols="12" md="3" class="custom7cols d-flex align-center" v-for="(product, index) in productBestSellerList" :key="product + index">
            <voucherCardComponent 
              :productData="product"
              :customCardClass="'mx-auto my-4'"
              @emitProductDetailData="goProductDetail"
              @emitProductAddToCart="onAddToCart"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import functionUtils from 'Utils/functionUtils'
import StoreChannelType from 'Enum/storeChannelType'
import VoucherCardComponent from 'Components/VoucherCardComponent'
export default {
  components: {
    VoucherCardComponent
  },
  data: () => ({
    cartList: [],
    productBestSellerList: [],
    categories: []
  }),
  computed: {
    ...mapGetters(['VOUCHER_PRODUCT_LIST_DATA', 'PRODUCT_CATEGORIES_PUBLIC_DATA', 'CARTS_DATA'])
  },
  watch: {
    CARTS_DATA() {
      this.cartList = this.CARTS_DATA;
    }
  },
  created () {
    this.cartList = this.CARTS_DATA;
    let cartList = JSON.parse(sessionStorage.getItem('carts'))
    if (cartList && this.cartList.length === 0 && cartList.length !== 0) {
      this.cartList = cartList
    }
    this.getProductList()
  },
  methods: {
    checkProductAddCart: function (cartList, productInfo, numberQuantityAdd) {
      return functionUtils.checkQuantityProductCanAddInCart(cartList, productInfo, numberQuantityAdd)
    },
    /**
     * Get products list
     */
    getProductList: function (category) {
      let filter = {
        params: {
          categoryId: category && category.id,
          isGetAll: false,
          bestSelling: true
        }
      }
      this.GET_PRODUCT_LIST(filter).then(
        function (res) {
          let data = res.data
          this.productBestSellerList = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              id: data[i].id,
              name: data[i].name,
              image: data[i].image_url,
              price: data[i].value,
              code: data[i].code,
              remainQuantity: data[i].warehouse,
              pricePromo: data[i].extra_info.product.price_promo,
              issuanceId: data[i].issuance_id,
              numberOfUsesVoucherValid: data[i].number_of_uses_voucher_valid
            }
            this.productBestSellerList.push(obj)
          }
        }.bind(this)
      )
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    /**
     * Go product detail
     */
    onAddToCart: function(product) {
      // let productInfo = {
      //   id: product.id,
      //   image: product.image,
      //   quantity: 1,
      //   remainQuantity: product.remainQuantity,
      //   price: product.pricePromo ? product.pricePromo : product.price,
      //   name: product.name,
      //   code: product.code
      // }
      // for (let i = 0, size = this.cartList.length; i < size; i++) {
      //   if (this.cartList[i].id == product.id) {
      //     if (this.cartList[i].quantity + 1 > product.remainQuantity) {
      //       this.$toast.open({
      //         message: this.$t("cart.addToCartOverQuantity"),
      //         type: "error",
      //         ...this.$toastConfig,
      //       });
      //       return
      //     }
      //   }
      // }
      let checkProductAddCart = this.checkProductAddCart(this.cartList, product, 1)
      if (checkProductAddCart) {
        let productInfo = {
          workpieceId: product.id,
          quantity: 1,
          storeChannelId: StoreChannelType.PRODUCT_PORTAL
        }
        this.ADD_VOUCHER_ORDER_CART(productInfo).then(
          function () {
            this.getVoucherOrderCart()
            this.$toast.open({
              message: this.$t("cart.addToCartSuccessed"),
              type: "success",
              ...this.$toastConfig,
            });
          }.bind(this)
        )
      } else {
        this.$toast.open({
          message: this.$t("cart.addToCartOverQuantity"),
          type: "error",
          ...this.$toastConfig,
        });
      }
    },
    /**
     * Go product detail
     */
    goProductDetail: function(data) {
      this.$router.push({
        name: "ProductDetail",
        params: { id: data.productId },
        query: {
          issuanceId: data.issuanceId
        }
      });
    },
    getVoucherOrderCart: function () {
      let filter = {
        params: {
          storeChannelId: StoreChannelType.PRODUCT_PORTAL
        }
      }
      this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    ...mapActions(['GET_PRODUCT_LIST', 'ADD_VOUCHER_ORDER_CART', 'GET_VOUCHER_ORDER_CART_LIST'])
  }
};
</script>
<style scoped lang="scss">
#about {
  background-color: #f4f7f5;
}
.cursor-pointer {
  cursor: pointer;
}
.category-hover:hover {
  background: #e5e5e5;
  padding: 15px;
  border-radius: 5px;
}
figure.sixteen-nine-img {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
figure.sixteen-nine-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
</style>
