<template>
  <v-card :class="customCardClass" width="100%">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <figure
      class="cursor-pointer sixteen-nine-img"
      @click="goProductDetail(productData.id, productData.issuanceId)"
    >
      <img :src="productData.image" :alt="productData.name" />
      <span
        v-if="productData.remainQuantity > 0"
        class="grey lighten-4 blue--text text--lighten-1 font-small p-1"
        style="position: absolute; top: 0; right: 0"
        >{{
          $t("product.detail.remainItems", { count: productData.remainQuantity })
        }}</span
      >
      <span
        v-else
        class="grey lighten-4 red--text text--lighten-1 font-small p-1"
        style="position: absolute; top: 0; right: 0"
        >{{ $t("productOrder.outOfStock") }}</span
      >
    </figure>
    <v-card-title
      class="cursor-pointer font-medium title-color"
      @click="goProductDetail(productData.id, productData.issuanceId)"
      style="height: 96px"
      >
      <div class="flex-parent">
        <div class="flex-child long-and-truncated">
          <span>{{ productData.name }}</span>
        </div>
      </div>
    </v-card-title>
    <v-divider class="mx-2 my-1"></v-divider>
    <v-card-text class="p-2 pb-5">
      <v-layout wrap>
        <v-flex
          xs12
          sm12
          md12
          class="text-left font-medium title-color font-weight-bold"
        >
          <span style="color: #ff5252" v-if="productData.pricePromo"
            >{{ formatMoney(productData.pricePromo) }} VND</span
          >
          <span
            :class="[`d-block`, !productData.pricePromo ? 'pt-3 pb-2' : '']"
            :style="
              productData.pricePromo
                ? 'text-decoration: line-through; font-size: 12px; font-weight: normal;'
                : 'color: #ff5252;'
            "
            >{{ formatMoney(productData.price) }} VND</span
          >
        </v-flex>
        <v-flex xs12 sm12 md12 class="text-right">
          <v-btn
            @click="onAddToCart(productData)"
            depressed
            color="error"
            :class="!productData.pricePromo ? 'py-3 pb-2' : ''"
          >
            {{ $t("common.buyNow") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>

    <!-- <v-card-actions class="justify-space-between">
              <v-card-subtitle class="font-medium title-color font-weight-bold">
                <span style="color: #ff5252;" v-if="productData.pricePromo">{{ formatMoney(productData.pricePromo) }} VND</span>
                <span :class="[`d-block`, !productData.pricePromo ? 'pt-3 pb-2' : '']" :style="productData.pricePromo ? 'text-decoration: line-through; font-size: 12px; font-weight: normal;' : 'color: #ff5252;'">{{ formatMoney(productData.price) }} VND</span>
              </v-card-subtitle>
              <v-btn
                @click="onAddToCart(productData)"
                depressed color="error"
                :class="!productData.pricePromo ? 'py-3 pb-2' : ''"
              >
                {{ $t('common.buyNow') }}
              </v-btn>
            </v-card-actions> -->
  </v-card>
</template>
<script>
import functionUtils from 'Utils/functionUtils'
export default {
  props: ['productData', 'customCardClass'],
  data: () => ({

  }),
  methods: {
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    isNotNull: function(value) {
      return !functionUtils.isNull(value)
    },
    goProductDetail: function (productId, issuanceId) {
      this.$emit('emitProductDetailData', {productId: productId, issuanceId: issuanceId})
    },
    onAddToCart: function(productData) {
      if (this.isNotNull(productData.numberOfUsesVoucherValid) && productData.numberOfUsesVoucherValid < 1) {
        this.$toast.open({
          message: this.$t('cart.productCanBuyTimes', {'0': productData.numberOfUsesVoucherValid}),
          type: "error",
          ...this.$toastConfig,
        });
        return
      }
      this.$emit('emitProductAddToCart', productData)
    }
  }
}
</script>
<style scoped>
#about {
  background-color: #f4f7f5;
}
.cursor-pointer {
  cursor: pointer;
}
.category-hover:hover {
  background: #e5e5e5;
  padding: 15px;
  border-radius: 5px;
}
figure.sixteen-nine-img {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
figure.sixteen-nine-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.flex-parent {
  display: flex;
}

.long-and-truncated {
  flex: 1;
  min-width: 0;/* Important for long words! */
}

.long-and-truncated span {
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;/* Important for long words! */
  word-break: normal;
}
</style>
