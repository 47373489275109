<template>
  <v-container class="no-space" fluid>
    <sliderImage />
    <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
      <productBestSeller />
      <bannerAds />
      <productNewest />
    </v-container>
    <!-- <news /> -->
  </v-container>
</template>

<script>
import SliderImage from "./SliderImage.vue";
import ProductBestSeller from  "./Product/BestSeller.vue"
import ProductNewest from "./Product/Newest.vue"
// import News from "./News.vue"
import BannerAds from "./BannerAds.vue"
export default {
  components: {
    SliderImage,
    ProductBestSeller,
    ProductNewest,
    // News,
    BannerAds
  },
};
</script>

<style lang="scss" scoped>
.no-space {
    margin: 0;
    padding: 0;
}
</style>
