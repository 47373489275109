<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <figure class="sixteen-nine-img">
          <img :src="imageUrl" />
        </figure>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import BannerTypeEnum from "Enum/bannerTypeEnum";
export default {
  data: () => ({
    imageUrl: null,
  }),
  created() {
    let filter = {
      params: {
        type: BannerTypeEnum.ADS,
      },
    };
    this.GET_BANNER_OF_ENTITY_BY_TYPE(filter).then(
      function(res) {
        let data = res.data
        if (data.length > 0) {
            this.imageUrl = data[0].image_url
        }
      }.bind(this)
    );
  },
  methods: {
    ...mapActions(["GET_BANNER_OF_ENTITY_BY_TYPE"]),
  },
};
</script>

<style scoped>
figure.sixteen-nine-img {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 36.25%;
  position: relative;
}
figure.sixteen-nine-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
</style>
