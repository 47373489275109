<template>
  <v-carousel
    hide-delimiter-background
    show-arrows-on-hover
    class="custom carousel"
  >
    <v-carousel-item class="cursor-pointer" v-for="(image, i) in images" :key="i" :src="image.url" @click="onClickBanner(image.link)">
      <v-sheet height="100%" style="background: transparent;">
        <v-row class="fill-height d-flex flex-column" align="center" justify="center">
          <div class="font-title-slider-size white--text">{{ image.title }}</div>
          <div v-if="image.title && image.description" class="line"></div>
          <div class="text-h6 white--text">{{ image.description }}</div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { mapActions } from "vuex";
import BannerTypeEnum from "Enum/bannerTypeEnum";
export default {
  data() {
    return {
      images: [
      ],
    };
  },
  created() {
    let filter = {
      params: {
        type: BannerTypeEnum.SLIDER,
      },
    };
    this.GET_BANNER_OF_ENTITY_BY_TYPE(filter).then(
      function(res) {
        let data = res.data;
        this.images = [];
        for (let i = 0, size = data.length; i < size; i++) {
          let obj = {
            title: data[i].title,
            description: data[i].description,
            url: data[i].image_url,
            link: data[i].link
          };
          this.images.push(obj);
        }
      }.bind(this)
    );
  },
  methods: {
    onClickBanner: function (link) {
      if (link) {
        window.open(link, '_blank');
      }
    },
    ...mapActions(["GET_BANNER_OF_ENTITY_BY_TYPE"]),
  },
};
</script>

<style lang="css" scoped>
.line {
  width: 150px;
  height: 5px;
  background-color: aqua;
  margin: 15px 0px 15px 0px;
}
.custom >>>.v-carousel__controls__item.v-btn {
  color: #FE6D6D;
}

.custom >>>.v-carousel__controls__item.v-btn.v-btn--active {
  color: #01BAEF;
}

.custom >>>.v-carousel__controls__item.v-btn.v-btn--active:before {
  opacity: 0;
}

/* .custom >>>.v-carousel__controls__item.v-btn:hover {
  color: blue;
} */

.custom >>>.v-carousel__controls__item.v-btn:hover:before {
  opacity: 0;
}
.carousel {
  height: 450px !important;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .carousel {
    height: 350px !important;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .carousel {
    height: 350px !important;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .carousel {
    height: 230px !important;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .carousel {
    height: 250px !important;
  }
}


</style>
